
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Routes } from '../../../routes';
import API from '../../../common/API';
import moment from 'moment';
import PostList from '../../Posts/PostList';
import AddeditEmployee from './AddEditEmployee';
import AddDiscountsForEmployee from '../discounts/AddDiscountsForEmployee';
import AddBenefitsForEmployee from '../benefits/AddBenefitsForEmployee';
import ContactList from '../../Contacts/ContactList';
import Files from '../../files';
import { FormatMoney, TransactionCalculationType, TransactionType } from '../../../common/helpers';
import BenefitsAssigned from './BenefitsAssigned';
import DiscountAssigned from './DiscountAssigned';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const EmployeeDetails = props => {
    const { t } = useTranslation("global")
    const [state, setState] = useState({
        User: null,
        CurrentSection: "general",
        UI: {

            showAssingDiscounts: false,
            showAssingBenefits: false,
        },
        TotalValueForEmployee: 0
    });
    const [showAddEdit, setShowAddEdit] = useState(false);

    useEffect(() => {
        request();
    }, []);
    const AssignSection = opt => {
        setState({
            ...state,
            CurrentSection: opt
        });

    }
    const request = async () => {
        let requestAPI = await API.getAction("Employees/Details?Id=" + props.match.params.id);

        if (requestAPI.data.status === "ok") {

            setState({
                ...state,
                User: requestAPI.data.response
            });
        }
    };
    const toggle = async (obj, reload) => {
        setShowAddEdit(!showAddEdit)
        if (reload !== undefined) {
            request();
        }
    };

    const toggleAssignDiscount = async (obj, reload) => {
        //state.UI.SelectedItem.set(obj);
        setState({
            ...state,
            UI: {
                ...state.UI,
                showAssingDiscounts: !state.UI.showAssingDiscounts
            }
        });

        if (reload !== undefined) {
            request();
        }
    };



    const calculateValueForEmployee = () => {
        let finalValue = 0;

        finalValue += state.User.basePayroll;

        {/* LOAD BENEFITS  */ }
        if (state.User.employeeBenefitsAssigned.filter(x => x.deleted === null)) {
            state.User.employeeBenefitsAssigned
                .filter(x => x.deleted === null && x.employeeBenefits.visibleByTheCompanyOnly === null)
                .forEach((item, index) => {
                    let payRollValue = state.User.basePayroll;
                    let valueToDisplay = item.employeeBenefits.type === 1 ? payRollValue * (item.employeeBenefits.value / 100) : item.employeeBenefits.type === 2 ? payRollValue / item.employeeBenefits.value : item.employeeBenefits.value;
                    finalValue = finalValue + (valueToDisplay * 1);

                })
        }

        //Discounts 
        if (state.User.employeeDiscountsAssigned.filter(x => x.deleted === null)) {
            state.User.employeeDiscountsAssigned
                .filter(x => x.deleted === null)
                .forEach((item, index) => {
                    let payRollValue = state.User.basePayroll;
                    let valueToDisplay = item.employeeDiscounts.type === 1 ? payRollValue * (item.employeeDiscounts.value / 100) :
                        item.employeeDiscounts.type === 2 ? payRollValue / item.employeeDiscounts.value :
                            item.employeeDiscounts.value;

                    finalValue = finalValue + (valueToDisplay * -1);
                })
        }

        return finalValue;
    }
    const calculateValueByEmployeeForCompany = () => {
        let finalValue = 0;

        finalValue += state.User.basePayroll;

        {/* LOAD BENEFITS  */ }
        if (state.User.employeeBenefitsAssigned.filter(x => x.deleted === null)) {
            state.User.employeeBenefitsAssigned
                .filter(x => x.deleted === null)
                .forEach((item, index) => {
                    let payRollValue = state.User.basePayroll;
                    let valueToDisplay = item.employeeBenefits.type === 1 ? payRollValue * (item.employeeBenefits.value / 100) : item.employeeBenefits.type === 2 ? payRollValue / item.employeeBenefits.value : item.employeeBenefits.value;
                    finalValue = finalValue + (valueToDisplay * 1);

                })
        }


        return finalValue;
    }

    const ProfileUI = () => {
        let obj = state.User;
        if (obj === null) {
            return (
                <></>
            );
        }
        return (<>
            <div className="col-12">
                <h3 className='mx-2'>{obj.firstName} {obj.lastName}</h3>
            </div>
            <div className="col-12 col-lg-4 col-md-12">
                <div className="card shadow-sm">
                    <div className="card-body">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item"><b>{obj.identifierType === 1 ? "ID" : "Passport"}: </b> <span className="float-right">{obj.identifier}</span></li>
                            <li className="list-group-item"><b>Género: </b> <span className="float-right">{obj.gender === 1 ? "Male" : "Female"}</span></li>
                            <li className="list-group-item"><b>Celular: </b> <span className="float-right">{obj.mobilePhone ? obj.mobilePhone : "-"}</span></li>
                            <li className="list-group-item"><b>Télefono: </b> <span className="float-right">{obj.phone ? obj.phone : "-"}</span></li>
                            {state.User.birthdate !== null ? <li className="list-group-item"><b>Fecha nacimiento: </b><span>{moment(state.User.birthdate).format("MMMM DD,YYYY")}<br /></span></li> : null}
                            <li className="list-group-item text-truncate" title={obj.email}><b>Email: </b> <span className="float-right">{obj.email}</span></li>

                        </ul>
                    </div>
                </div>
                <ul className="nav nav-pills nav-fill  flex-column my-4 mx-4">
                    <li className="nav-item"><button className={" btn-block btn text-left " + (state.CurrentSection === "storyline" ? "btn-primary" : "btn")} onClick={() => AssignSection("storyline")}>Posts</button></li>
                    <li className="nav-item"><button className={" btn-block btn text-left " + (state.CurrentSection === "general" ? "btn-primary" : "btn")} onClick={() => AssignSection("general")}>General</button></li>
                    <li className="nav-item d-none"><button className={" btn-block btn  text-left " + (state.CurrentSection === "projects" ? "btn-primary" : "btn")} onClick={() => AssignSection("projects")}>Proyectos</button></li>
                    <li className="nav-item d-none"><button className={" btn-block btn  text-left " + (state.CurrentSection === "files" ? "btn-primary" : "btn")} onClick={() => AssignSection("files")}>Archivos</button></li>
                    <li className="nav-item"><button className={" btn-block btn  text-left " + (state.CurrentSection === "contacts" ? "btn-primary" : "btn")} onClick={() => AssignSection("contacts")}>Contactos</button></li>
                </ul>
            </div>
            <div className="col-12 col-md-12 col-lg-8">
                {state.CurrentSection === "storyline" ? TimelineUI() : null}
                {state.CurrentSection === "general" ? GeneralUI() : null}
                {state.CurrentSection === "projects" ? "projects" : null}
                {state.CurrentSection === "files" ? FilesUI() : null}
                {state.CurrentSection === "contacts" ? ContactUI() : null}
            </div>
        </>);
    }



    const FilesUI = () => {
        return (<Files EmployeeId={props.match.params.id} />)
    }
    const TimelineUI = () => {
        return (<PostList EmployeeId={props.match.params.id} />)
    }

    const ContactUI = () => {
        return (<ContactList EmployeeId={props.match.params.id} />)
    }

    const GeneralUI = () => {
        return (
            <div className="row">
                <div className="col-12">

                    <div className="card shadow-sm mb-4">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-auto">
                                    <h5 className="display-5 d-inline">{t('General Info')}</h5>
                                </div>
                                <div className="col ml-auto text-right">
                                    <button className="btn btn-primary d-inline ml-auto btn-sm" onClick={() =>
                                        toggle({
                                            id: "",
                                            created: "",
                                            title: "",
                                            content: "",
                                            employeeId: props.EmployeeId,
                                            customerId: props.CustomerId,
                                            employeePositionId: props.EmployeePositionId
                                        })}>
                                        <i className="fa fa-edit" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-0">
                            <table className='table '>
                                <tbody>
                                    {state.User.employeePosition !== null ?
                                        <tr>
                                            <th>
                                                Posición
                                            </th>
                                            <td>{state.User.employeePosition.name}</td>
                                        </tr> : null}
                                    {state.User.employeePosition.department !== null ?
                                        <tr>
                                            <th>
                                                Departamento
                                            </th>
                                            <td>{state.User.employeePosition.department.name}</td>
                                        </tr> : null}
                                    {state.User.beginWorkingDate !== null ?
                                        <tr>
                                            <th>
                                                Inicio Laboral
                                            </th>
                                            <td> {moment(state.User.beginWorkingDate).fromNow()}</td>
                                        </tr> : null}
                                    {state.User.bankAccount !== null ?
                                        <tr>
                                            <th>
                                                Cuenta Bancaria
                                            </th>
                                            <td> {state.User.bankAccount}</td>
                                        </tr> : null}
                                    {state.User.bankNavigation !== null ?
                                        <tr>
                                            <th>
                                                Banco
                                            </th>
                                            <td> {state.User.bankNavigation.name}</td>
                                        </tr> : null}
                                    {state.User.basePayroll !== null ?
                                        <tr>
                                            <th>
                                                Salario Base
                                            </th>
                                            <td> {FormatMoney(state.User.basePayroll)}</td>
                                        </tr> : null}
                                    {state.User.payrollTypeId !== null ?
                                        <tr>
                                            <th>
                                                Tipo de Nómina
                                            </th>
                                            <td> {state.User.payrollTypeId === 1 ? "Weekly" : state.User.payrollTypeId === 2 ? "Bi-weekly" : "Monthly"}</td>
                                        </tr> : null}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <div className="col-12 ">
                    <BenefitsAssigned employeeId={props.match.params.id} />
                </div>
                <div className="col-12 ">
                    <DiscountAssigned employeeId={props.match.params.id} />
                </div>
                <div className="col-12">
                    <div className="card shadow-sm mb-4">
                        <div className="card-header">

                            <label className="display-5">Detalle mensual del empleado</label>
                        </div>
                        <div className="card-body table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Concepto</th>
                                        <th className="text-right">Monto</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Salario Base
                                        </td>
                                        <td className="text-right">
                                            {FormatMoney(state.User.basePayroll)}
                                        </td>
                                    </tr>
                                    {/* LOAD BENEFITS  */}
                                    {state.User.employeeBenefitsAssigned.filter(x => x.deleted === null) ?
                                        state.User.employeeBenefitsAssigned
                                            .filter(x => x.deleted === null && x.employeeBenefits.visibleByTheCompanyOnly === null)
                                            .map((item, index) => {
                                                let payRollValue = state.User.basePayroll;

                                                let valueToDisplay = item.employeeBenefits.type === TransactionCalculationType.percentage ? payRollValue * (item.employeeBenefits.value / 100) :
                                                    item.employeeBenefits.type === TransactionCalculationType.factor ? payRollValue / item.employeeBenefits.value : item.employeeBenefits.value;
                                                return (<tr key={index}>
                                                    <td className="">
                                                        {item.employeeBenefits.name}
                                                    </td>
                                                    <td className=" text-right">
                                                        <span className=""> {FormatMoney(valueToDisplay)}</span>
                                                    </td>
                                                </tr>)
                                            }) : null
                                    }
                                    {/* LOAD DISCOUNTS */}
                                    {state.User.employeeDiscountsAssigned.filter(x => x.deleted === null) ?
                                        state.User.employeeDiscountsAssigned
                                            .filter(x => x.deleted === null)
                                            .map((item, index) => {
                                                let payRollValue = state.User.basePayroll;
                                                let valueToDisplay = item.employeeDiscounts.type === TransactionCalculationType.percentage ? payRollValue * (item.employeeDiscounts.value / 100) :
                                                    item.employeeDiscounts.type === TransactionCalculationType.factor ? payRollValue / item.employeeDiscounts.value : item.employeeDiscounts.value;

                                                return (<tr key={index}>
                                                    <td className="">
                                                        {item.employeeDiscounts.name} ({item.employeeDiscounts.percentage ? item.employeeDiscounts.value + "%" : item.employeeDiscounts.value})
                                                    </td>
                                                    <td className=" text-right">
                                                        <span className=" text-danger"> {FormatMoney(valueToDisplay)}</span>
                                                    </td>
                                                </tr>)
                                            }) : null
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>

                                        </td>
                                        <th className="text-right">
                                            {FormatMoney(calculateValueForEmployee())}
                                        </th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>


                {/* COST FOR THE COMPANY */}
                <div className="col-12">
                    <div className="card shadow-sm mb-4">
                        <div className="card-header">

                            <label className="display-5">Costo mensual del empleado para la empresa</label>
                        </div>
                        <div className="card-body table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Concepto</th>
                                        <th className="text-right">Monto</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            Salario Base
                                        </td>
                                        <td className="text-right">
                                            {FormatMoney(state.User.basePayroll)}
                                        </td>
                                    </tr>
                                    {/* LOAD BENEFITS  */}
                                    {state.User.employeeBenefitsAssigned.filter(x => x.deleted === null) ?
                                        state.User.employeeBenefitsAssigned
                                            .filter(x => x.deleted === null)
                                            .map((item, index) => {
                                                let payRollValue = state.User.basePayroll;

                                                let valueToDisplay = item.employeeBenefits.type === 1 ? payRollValue * (item.employeeBenefits.value / 100) : item.employeeBenefits.type === 2 ? payRollValue / item.employeeBenefits.value : item.employeeBenefits.value;
                                                return (<tr key={index}>
                                                    <td className="">
                                                        {item.employeeBenefits.name} ({item.employeeBenefits.percentage ? item.employeeBenefits.value + "%" : item.employeeBenefits.value})
                                                    </td>
                                                    <td className=" text-right">
                                                        <span className=""> {FormatMoney(valueToDisplay)}</span>
                                                    </td>
                                                </tr>)
                                            }) : null
                                    }

                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>

                                        </td>
                                        <th className="text-right">
                                            {FormatMoney(calculateValueByEmployeeForCompany())}
                                        </th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>

            </div >)
    }
    console.log("state value ", state);
    return (
        <>
            {showAddEdit ?
                <AddeditEmployee toggle={toggle} show={showAddEdit} model={state.User} />
                : null}
            {state.UI.showAssingDiscounts ?
                <AddDiscountsForEmployee toggle={toggleAssignDiscount} show={state.UI.showAssingDiscounts} model={state.User} />
                : null}
            <Container>
                <div className="row">
                    <nav aria-label="breadcrumb" className="mb-2">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/"><i className="fa fa-home"></i></Link></li>

                            <li className="breadcrumb-item"><Link to={Routes.Employees.path}>Empleados</Link></li>
                            <li className="breadcrumb-item">Detalles</li>
                        </ol>
                    </nav>
                    {state !== undefined && state.User ?
                        ProfileUI()
                        : null}
                </div>
            </Container>
        </>
    )
}
export default EmployeeDetails;