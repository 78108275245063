import React, { useState, useEffect, useContext } from "react"
import { db, defaultDataDB } from "./common/db"
import { Route, Switch, useHistory } from "react-router-dom"
import { Routes } from "./routes"
import ErrorBoundary from "./components/Common/BoundaryError"

import Preloader from "./components/Preloader"

import ActivityDetails from "./components/Agenda/ActivityDetails"
import Files from "./components/files"
import { SocketContext, socketComponent } from "./Context/socket"
import PreferencesContextProvider from "./Context/preferences"
import { parseJwt } from "./common/helpers"
import GetCurrentUser, { parsedUser } from "./common/GetCurrentUser"
import Services from "./components/Services/index"
import Products from "./components/Products/index"
import CustomerBalance from "./components/Customers/CustomerBalance"
import QuoteDashboard from "./components/Quotes/index"
import QuotePrint from "./components/Quotes/Print"
import AccountReceivables from "./components/AccountReceivables/index"
import ReceivedPayments from "./components/ReceivedPayments/index"
import InvoiceDashboard from "./components/Invoices/index"
import InvoicePrint from "./components/Invoices/Print"
import AddEditQuotes from "./components/Quotes/AddEditQuotes"
import AddEditInvoice from "./components/Invoices/AddEditInvoice"
import AddEditCreditNote from "./components/Invoices/AddEditCreditNote"

import PayrollDashboard from "./components/HR/DashboardOverview"

import { default as Employees } from "./components/HR/Employees/index"
import EmployeeDetails from "./components/HR/Employees/EmployeeDetails"
import { default as Payrolls } from "./components/HR/Payroll/index"
import PayrollDetails from "./components/HR/Payroll/PayrollDetails"

import PayrollPaymentDetails from "./components/HR/Payroll/PayrollPaymentDetails"
import PayrollReport from "./components/HR/Payroll/PayrollReport"

import GetIn from "./components/GetIn/GetIn"
import CustomerStatements from "./components/Reports/CustomersStatements"

import { Toaster } from "react-hot-toast"

import { useLiveQuery } from "dexie-react-hooks"
import Sidebar from "./components/Common/Sidebar/Sidebar"

import PrintPayment from "./components/Invoices/PrintPayment"
import SelectOrganization from "./components/Organizations/SelectOrganization"

import Login from "./components/Profile/Login"
import ForgotPassword from "./components/Profile/ForgotPassword"
import routesWithComponent from "./pages/routesWithComponent"

import Register from "./components/Profile/Register"

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const user = parsedUser()
  const history = useHistory()
  const settings = useLiveQuery(() =>
    db.settings.where({ id: user.ID }).first()
  )
  const [sidebarToggle, setSidebarToggle] = useState(true)
  const [loaded, setLoaded] = useState(false)

  const socket = socketComponent(() => {
    const theUser = parseJwt(GetCurrentUser())
    if (theUser) {
      socket.invoke("Online", theUser.ID)
    }
  })

  const validateUser = async () => {
    if (!user) {
      console.log("ENTRO", user)

      history.push(Routes.SignIn.path)
    } else {
      //Check for settings
      let settingExist = await db.settings.where({ id: user.ID }).first()

      if (!settingExist) {
        await db.settings.put({
          id: user.ID,
          application: "finance",
          language: "en",
        })
      }
    }
  }

  useEffect(() => {
    validateUser()
    console.log("Settings", settings)
    const timer = setTimeout(() => setLoaded(true), 1000)
    return () => clearTimeout(timer)
  }, [])

  const handleSidebarShow = () => {
    setSidebarToggle(!sidebarToggle)
  }

  return (
    <PreferencesContextProvider>
      <SocketContext.Provider value={socket}>
        <Route
          {...rest}
          render={props => (
            <>
              <Toaster />
              <Preloader show={!loaded} />
              {/* <OmniBar {...props} settings={settings} /> */}
              <Sidebar
                settings={settings}
                sidebarToggle={sidebarToggle}
                handleSidebarShow={handleSidebarShow}
              />
              <main className={sidebarToggle ? "content" : "content ms-5"}>
                <div className="row mx-0">
                  <div className="contentContainer py-2">
                    {/* <Navbar {...props} /> */}

                    <Component {...props} />
                    {/* <Footer
                      toggleSettings={toggleSettings}
                      showSettings={showSettings}
                    /> */}
                  </div>
                </div>
              </main>
            </>
          )}
        />
      </SocketContext.Provider>
    </PreferencesContextProvider>
  )
}

const App = () => {
  useEffect(() => {
    defaultDataDB()
  }, [])

  return (
    <ErrorBoundary>
      <Switch>
        {routesWithComponent.map(item => {
          return item.withSidebar ? (
            <RouteWithSidebar
              // key={index}
              exact
              path={item.path}
              component={item.component}
            />
          ) : (
            <Route
              // key={index}
              exact
              path={item.path}
              component={item.component}
            />
          )
          //  return obj
        })}

        <Route
          exact
          path={Routes.CustomerBalance.path}
          component={CustomerBalance}
        />
        <RouteWithSidebar
          exact
          path={Routes.Services.path}
          component={Services}
        />
        <RouteWithSidebar
          exact
          path={Routes.Products.path}
          component={Products}
        />
        <RouteWithSidebar
          exact
          path={Routes.QuoteDashboard.path}
          component={QuoteDashboard}
        />
        <RouteWithSidebar
          exact
          path={Routes.AccountReceivables.path}
          component={AccountReceivables}
        />
        <RouteWithSidebar
          exact
          path={Routes.AddEditQuote.path}
          component={AddEditQuotes}
        />
        <Route exact path={Routes.QuotePrint.path} component={QuotePrint} />
        <Route exact path={Routes.PrintPayment.path} component={PrintPayment} />
        <RouteWithSidebar
          exact
          path={Routes.InvoiceDashboard.path}
          component={InvoiceDashboard}
        />
        <RouteWithSidebar
          exact
          path={Routes.AddEditInvoice.path}
          component={AddEditInvoice}
        />

        <RouteWithSidebar
          exact
          path={Routes.AddEditCredit.path}
          component={AddEditCreditNote}
        />

        <Route exact path={Routes.InvoicePrint.path} component={InvoicePrint} />

        <RouteWithSidebar
          exact
          path={Routes.ReceivedPayments.path}
          component={ReceivedPayments}
        />
        {/* END FINANCE */}

        {/* PAYROLL */}

        <RouteWithSidebar
          exact
          path={Routes.DashboardPayroll.path}
          component={PayrollDashboard}
        />

        <RouteWithSidebar
          exact
          path={Routes.Employees.path}
          component={Employees}
        />
        <RouteWithSidebar
          exact
          path={Routes.EmployeeDetails.path}
          component={EmployeeDetails}
        />

        <RouteWithSidebar
          exact
          path={Routes.Payrolls.path}
          component={Payrolls}
        />
        <RouteWithSidebar
          exact
          path={Routes.PayrollDetails.path}
          component={PayrollDetails}
        />

        <Route
          path={Routes.PayrollPaymentDetails.path}
          exact
          component={PayrollPaymentDetails}
        />
        <Route path={Routes.PayrollReport.path} component={PayrollReport} />

        <RouteWithSidebar exact path={Routes.Files.path} component={Files} />

        <Route path={Routes.getin.path} component={GetIn} />
        <Route
          path={Routes.CustomerStatements.path}
          component={CustomerStatements}
        />
        <RouteWithSidebar
          exact
          path={Routes.ActivityDetails.path}
          component={ActivityDetails}
        />

        <Route
          path={Routes.SelectOrganization.path}
          component={SelectOrganization}
        />
        <Route exact path={Routes.SignIn.path} component={Login} />
        <Route exact path={Routes.Register.path} component={Register} />
        <Route
          exact
          path={Routes.ForgotPassword.path}
          component={ForgotPassword}
        />
      </Switch>
    </ErrorBoundary>
  )
}

export default App
