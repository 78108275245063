//import moment from "moment"
import React, { useEffect, useState } from "react"

import { useHistory } from "react-router"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"

import { Routes } from "../../routes"
import AddEditCustomer from "./AddEditCustomer"
import emptyImg from "../../assets/img/illustrations/no_content.svg"
import CRUDField from "../core/genericCRUD/CRUDField"
import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css

import Pagination from "../core/Pagination"
import {
  Card,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Row,
} from "react-bootstrap"

//Translation
import { useTranslation } from "react-i18next"
import ActionBar from "../core/ActionBar"
import Filter from "../core/Filter/Filter"

const CustomerList = props => {
  const [customerType, setCustomerType] = useState("all")
  const history = useHistory()
  const { t } = useTranslation("global")
  // FILTER RELATED
  const [showFilter, setShowFilter] = useState(true)
  const toggleFilter = () => {
    setShowFilter(!showFilter)
  }
  const user = parsedUser()
  const filterFields: CRUDField[] = [
    {
      name: "CustomerType",
      title: "CustomerType",
      dbType: "string",
      htmlType: "select",
      containerClass: "col-4",
      dataSourceValueField: "id",
      dataSourceTextField: "name",
      dataSourceLocal: [
        { id: "prospect", name: t("prospect") },
        { id: "customer", name: t("customer") },
      ],
    },
    {
      name: "Search",
      title: "search",
      htmlType: "text",
      dbType: "date",
      containerClass: "col-4",
    },
  ]
  const [filterObj, setFilterObj] = useState({})
  const getFilterValues = obj => {
    console.log("obj", obj)
    setFilterObj(obj)
  }
  useEffect(() => {
    if (filterObj) {
      bindDataRemote()
    }
  }, [filterObj])
  // FILTER RELATED END

  const [state, setState] = useState({
    Customers: [],
    type: [
      { id: "all", name: t("all") },
      { id: "prospect", name: t("prospect") },
      { id: "customer", name: t("customer") },
    ],
    UI: {
      IsLoading: false,
      showAddEdit: false,
      Customerselected: null,
    },
    currentPage: 0,
    Quantity: 20,
    TotalPages: 0,
    TotalRecords: null,
    OnlyPendingToPaid: false,
  })

  const theUser = parsedUser()
  useEffect(() => {
    if (theUser === null) {
      history.push(Routes.SignIn.path)
    }
    bindDataRemote()
  }, [state.currentPage, customerType])

  const onPageChanged = async obj => {
    setState({
      ...state,
      currentPage: obj,
    })

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }

  const bindDataRemote = async () => {
    let Query =
      "OrganizationId=" +
      theUser.OrganizationId +
      "&Search=" +
      (filterObj.Search ?? "") +
      "&Quantity=" +
      state.Quantity +
      "&Page=" +
      state.currentPage +
      "&OnlyPendingToPaid=" +
      state.OnlyPendingToPaid
    if (filterObj.CustomerType !== "all") {
      Query += "&CustomerType=" + (filterObj.CustomerType ?? "")
    }
    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("customer/List", Query)

    if (requestAPI.data.status === "ok") {
      setState({
        ...state,
        Customers: requestAPI.data.response,
        TotalPages: requestAPI.data.quantityOfPages,
        TotalRecords: requestAPI.data.quantityOfRecords,
      })
    }
  }

  const [showAddEdit, setShowAddEdit] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const toggleAddEdit = async (obj, reload) => {
    setSelectedItem(obj)
    setShowAddEdit(!showAddEdit)
    if (reload) {
      await bindDataRemote()
    }
  }

  const deleteConfirm = async obj => {
    let currentUser = parsedUser()

    confirmAlert({
      title: t("confirm") ?? "",
      message: t("questions.sure_delete") + obj.firstName + "?",
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            await API.getAction(
              "customer/delete?id=" + obj.id + "&UserId=" + currentUser.ID
            )
            await bindDataRemote()
          },
        },
        {
          label: t("no"),
          //     onClick: () => alert('Click No')
        },
      ],
    })
  }

  return (
    <>
      {showAddEdit ? (
        <AddEditCustomer
          toggle={toggleAddEdit}
          show={showAddEdit}
          model={selectedItem}
        />
      ) : null}
      <Container>
        <Row>
          <Col xs={12} className="mb-4">
            <ActionBar
              title={t("customers")}
              icon={"fa fa-users"}
              actions={
                <>
                  <button
                    className="btn btn-primary btn-sm me-2"
                    onClick={() =>
                      toggleAddEdit({
                        id: "",
                        identifier: null,
                        firstName: null,
                        created: null,
                        updated: null,
                        deleted: null,
                        organizationId: null,
                        phone: null,
                        phone2: null,
                        email: null,
                        country: "DO",
                      })
                    }
                  >
                    <i className="fa fa-plus " />
                  </button>
                  <button
                    className={
                      "btn  btn-sm  " + (showFilter ? "btn-secondary" : "")
                    }
                    onClick={() => toggleFilter()}
                  >
                    <i className="fa fa-filter" />
                  </button>
                  <DropdownButton
                    align="start"
                    size="sm"
                    className="ms-2 d-inline"
                    id="dropdown-basic-button"
                    variant="primary"
                    title={<i className="fa fa-ellipsis-v" />}
                  >
                    <Dropdown.Item
                      as="button"
                      onClick={() => {
                        window.open(
                          "https://api.pleelo.com/api/customer/ExportCustomers?OrganizationId=" +
                            theUser.OrganizationId,
                          "_blank"
                        )
                      }}
                    >
                      {t("export_excel")}
                    </Dropdown.Item>
                  </DropdownButton>
                </>
              }
            ></ActionBar>

            {/* FILTERS */}
            {showFilter && filterFields && filterFields.length > 0 && (
              <Filter
                filterAction={() => {
                  bindDataRemote()
                }}
                fields={filterFields}
                setFilterFields={getFilterValues}
              />
            )}
          </Col>

          <Col xs={12}>
            {state.Customers.length === 0 ? (
              <div className="row">
                <div className="col-6 mx-auto text-center">
                  <img
                    src={emptyImg}
                    className="img-fluid"
                    style={{ maxWidth: "50%" }}
                    alt=""
                  />
                </div>
              </div>
            ) : null}
            {state.Customers.length > 0 ? (
              <div className="card shadow-sm">
                <div className="card-body p-0  ">
                  {state.Customers.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table table-striped w-100">
                        <thead>
                          <tr>
                            <th>{t("name")}</th>
                            <th>{t("identifier")}</th>
                            <th>{t("type")}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.Customers.map((item, index) => {
                            let path = Routes.CustomerDetails.clean + item.id
                            return (
                              <tr className=" px-0 " key={index}>
                                <td
                                  className=" clickable text-truncate"
                                  onClick={() => history.push(path)}
                                >
                                  <label className="" title={item.firstName}>
                                    {item.firstName} {item.lastName}
                                  </label>
                                </td>
                                <td> {item.identifier}</td>
                                <td>
                                  {item.invoice ? t("customer") : t("prospect")}
                                </td>
                                <td className=" text-right">
                                  <div className="btn-group">
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={() => toggleAddEdit(item, false)}
                                    >
                                      <i className="fa fa-edit" />
                                    </button>
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={() => deleteConfirm(item)}
                                      type="button"
                                    >
                                      <i className="fa fa-trash text-danger" />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}

            <Card className="my-4">
              <Card.Footer className="p-1">
                <Pagination
                  totalPages={state.TotalPages}
                  totalRecords={state.TotalRecords}
                  currentPage={state.currentPage}
                  onPageChanged={onPageChanged}
                />
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default CustomerList
