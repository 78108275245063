import React, { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"

import { Routes } from "../../routes"

import PayrollList from "./Payroll/PayrollList"
import EmployeeList from "./Employees/EmployeeList"

import Birthdates from "../PayrollContainer/Birthdates/Birthdates"
import { parsedUser } from "../../common/GetCurrentUser"
import DashboardSummary from "./Dashboard/DashboardSummary"

const DashboardOverview = ({ history }) => {
  const currentUser = parsedUser()

  useEffect(() => {
    if (!currentUser) {
      window.location = Routes.SignIn.path
    }
    //Content on load
    localStorage.setItem("selectedApp", "payroll")
  }, [])
  return (
    <Container>
      <DashboardSummary />
      <Row>
        <Col xs={12} md={9} lg={9}>
          <PayrollList noOptions Quantity={5} />

          <EmployeeList noOptions Quantity={5} />
        </Col>
        <Col xs={12} md={3} lg={3}>
          <Birthdates />
        </Col>
      </Row>
    </Container>
  )
}

export default DashboardOverview
