import React, { useEffect, useState } from "react"
import { Card, Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { parsedUser } from "../../../common/GetCurrentUser"
import API from "../../../common/API"

function DashboardSummary() {
  const { t } = useTranslation("global")
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<any>(null)
  const user = parsedUser()
  const bindDataRemote = async () => {
    try {
      setIsLoading(true)
      let query = "OrganizationId=" + user.OrganizationId
      const request = await API.getAction("employeeDashboard/Summary", query)

      if (request.data.status === "ok") {
        setData(request.data.response)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    bindDataRemote()
  }, [])
  if (data == null) {
    return null
  }
  return (
    <Row className="mb-4">
      <Col>
        <Card>
          <Card.Header>
            <Card.Title>{data.employees}</Card.Title>
          </Card.Header>
          <Card.Body>{t("Employees")}</Card.Body>
        </Card>
      </Col>
      <Col>
        <Card>
          <Card.Header>
            <Card.Title>{data.payroll}</Card.Title>
          </Card.Header>
          <Card.Body>{t("Payrolls")}</Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default DashboardSummary
